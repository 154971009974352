<template>
  <section class="js-page-center">
    <div class="centerpage">
      <div class="user js-usercontent">
        <!-- <div class="user-name ellps">你好: {{username}}</div> -->
      </div>
      <div class="svip-not js-notVip">
        <div class="title-line">
          <span class="svip-title">好大夫会员专享医疗服务</span>
          <span class="open-service">开启服务</span>
        </div>
        <div class="svip-label">
          推荐医生 | 三甲专家免费问｜ 面诊/住院我们管
        </div>
      </div>

      <div class="order">
        <router-link class="order-hd" to="/myorder">
        <div>我的订单</div>
         <div class="order-block">
          <a href="#" class="order-block-item">
            <img
              src="https://i2.hdfimg.com/ssi/usercenter/image/order_unpay.png"
              alt="待支付"
              class="o-b-i-icon"
            />
            <span class="o-b-i-content">待支付</span>
          </a>
          <a href="#" class="order-block-item">
            <img
              src="https://i2.hdfimg.com/ssi/usercenter/image/order_doing.png"
              alt="进行中"
              class="o-b-i-icon"
            />
            <span class="o-b-i-content">进行中</span>
          </a>
          <a href="#" class="order-block-item">
            <img
              src="https://i2.hdfimg.com/ssi/usercenter/image/order_done.png"
              alt="已完成"
              class="o-b-i-icon"
            />
            <span class="o-b-i-content">已完成</span>
          </a>
          <a href="#" class="order-block-item">
            <img
              src="https://i2.hdfimg.com/ssi/usercenter/image/order_cancel.png"
              alt="已取消"
              class="o-b-i-icon"
            />
            <span class="o-b-i-content">已取消</span>
          </a>
          <a href="#" class="order-block-item">
            <img
              src="https://i2.hdfimg.com/ssi/usercenter/image/order_all.png"
              alt="全部"
              class="o-b-i-icon"
            />
            <span class="o-b-i-content">全部</span>
          </a>
        </div>
        </router-link>
      </div>

      <div class="account">
        <div class="account-hd">我的账户</div>
        <div class="account-block">
          <a href="#" class="account-block-item">
            <span class="a-b-i-value"
              >0.00<span class="value-unit">元</span></span
            >
            <span class="a-b-i-content">余额</span>
          </a>
          <a href="#" class="account-block-item">
            <span class="a-b-i-value">0<span class="value-unit">个</span></span>
            <span class="a-b-i-content">好豆</span>
          </a>
          <a href="#" class="account-block-item">
            <span class="a-b-i-value">0<span class="value-unit">张</span></span>
            <span class="a-b-i-content">公益补贴券</span>
          </a>
        </div>
      </div>

      <div class="tools">
        <div class="tools-hd">工具与服务</div>
        <div class="tools-block">
          <a href="#"  v-for="item in toots" :key="item.name" class="tools-block-item">
            <img
              :src="item.icon"
              :alt="item.name"
              class="t-b-i-icon"
            />
            <span class="t-b-i-content">{{item.name}}</span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data(){
    return {
      toots:[
        {icon:'https://i2.hdfimg.com/ssi/usercenter/image/tool_mydoc.png',name:"我的医生"},
        {icon:'https://i2.hdfimg.com/ssi/usercenter/image/tool_patientinfo.png',name:"患者管理"},
        {icon:'https://i2.hdfimg.com/ssi/usercenter/image/tool_prescription.png',name:"我的处方"},
        {icon:'https://i2.hdfimg.com/ssi/usercenter/image/tool_comment.png',name:"诊后评价"},
        {icon:'https://i2.hdfimg.com/ssi/usercenter/image/tool_privatedoc.png',name:"私人医生"}
      ]
    }
  },
   computed:{
     username(){
       return this.$store.getters.username
     }
  }
}
</script>
<style scoped>
.svip-not {
  position: relative;
  z-index: 999;
  padding: 10px 10px 14px;
  margin: -90px 10px 0;
  height: 75px;
  border-radius: 8px;
  background: url(//fe1.hdfimg.com/nhealthpal/static/images/svip/article_backimg.png)
    no-repeat;
  background-size: 100% 100%;
}
@media screen and (max-width: 370px) {
  .svip-not {
    padding: 10px 4px 14px 12px;
  }
}
.svip-not .title-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.svip-not .title-line .svip-title {
  font-size: 18px;
  color: #7a4713;
}
.svip-not .title-line .svip-tip-title {
  font-size: 2.9333vw;
}
.svip-not .title-line .open-service {
  display: inline-block;
  background-image: linear-gradient(180deg, #fe921d 0, #fd7304 100%);
  box-shadow: 0 2px 3px 0 #772f00;
  border-radius: 16px;
  padding: 3px 7px;
  font-size: 14px;
  color: #fff;
  margin-right: 10px;
}
.svip-not .svip-label {
  font-size: 13px;
  color: #7a4713;
  margin-top: 6px;
}
@media screen and (max-width: 370px) {
  .svip-not .svip-label {
    font-size: 12px;
  }
}
.svip-opened {
  position: relative;
  color: #7a4713;
  z-index: 999;
  margin: -90px 10px 0;
  padding: 10px 10px 14px;
  border-radius: 8px;
  background: url(//fe1.hdfimg.com/nhealthpal/static/images/svip/article_backimg.png)
    no-repeat;
  background-size: 100% 100%;
}
@media screen and (max-width: 370px) {
  .svip-opened {
    padding: 10px 4px 14px 12px;
  }
}
.svip-opened .svip-logo {
  display: flex;
  align-items: center;
}
.svip-opened .svip-logo .svip-img {
  display: inline-block;
  width: 26px;
  height: 17px;
  background: url(//fe1.hdfimg.com/nhealthpal/static/images/svip/crown_v.png)
    no-repeat;
  background-size: 100% 100%;
}
.svip-opened .svip-logo .svip-title {
  font-size: 18px;
  margin-left: 8px;
}
.svip-opened .svip-logo .svip-tip-title {
  font-size: 2.9333vw;
}
.svip-opened .svip-logo .svip-type {
  font-size: 14px;
  margin-left: 8px;
}
.svip-opened .svip-valid-date {
  font-size: 13px;
  margin-top: 5px;
}
.svip-opened .svip-flex-btn {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
.svip-opened .svip-flex-btn .svip-use-service {
  background-image: linear-gradient(180deg, #fe921d 0, #fd7304 100%);
  box-shadow: 0 2px 2px 0 #772f00;
  border-radius: 17px;
  width: 156px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  height: 25px;
  line-height: 25px;
}
html,
body {
  background-color: #f0f0f0;
}
body {
  padding-bottom: 100px;
}
.hdf-nav-custom-goHome {
  background: #fff !important;
  color: #055ad7 !important;
  border-radius: 3px !important;
  padding: 0 8px !important;
  height: 26px !important;
  line-height: 26px !important;
  font-size: 12px !important;
}
.centerpage .user {
  position: relative;
  padding: 15px 0 100px 20px;
  /* background-color: #46a0f0; */
}
.centerpage .user.paddingBottom50 {
  padding-bottom: 122px;
}
.centerpage .user .user-name {
  font-size: 19px;
  color: #fff;
  max-width: 60%;
}
.centerpage .user .user-bindphone {
  font-size: 14px;
  color: #fff;
  margin-top: 4px;
  max-width: 60%;
}
.centerpage .user .user-nickname {
  font-size: 14px;
  color: #fff;
  margin-top: 7px;
  max-width: 60%;
}
.centerpage .user .user-nickname .user-nickname-set {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  color: #fff;
  padding: 0 10px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 12px;
}
.centerpage .user .user-download {
  font-size: 14px;
  color: #eef6ff;
  margin-top: 14px;
  display: none;
}
@media screen and (max-width: 370px) {
  .centerpage .user .user-download {
    margin-top: 20px;
  }
}
.centerpage .user .user-download .user-download-go {
  color: #fff16c;
}
.centerpage .user .user-download .user-download-go:after {
  display: inline-block;
  content: "";
  width: 8px;
  height: 8px;
  border: 1px solid #fff16c;
  border-top: 0;
  border-left: 0;
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.centerpage .user .user-notify {
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 0;
}
.centerpage .user .user-notify .user-notify-set {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 12px;
  font-size: 14px;
  color: #fff;
  height: 23px;
  line-height: 23px;
  padding: 0 8px;
}
.centerpage .user .user-notify .user-notify-message {
  position: relative;
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 12px;
  font-size: 14px;
  color: #fff;
  height: 23px;
  line-height: 23px;
  padding: 0 8px;
  margin-left: 10px;
}
.centerpage .user .user-notify .user-notify-message .u-n-m-num {
  position: absolute;
  top: -10px;
  right: 0;
  background: red;
  border-radius: 7px;
  font-size: 10px;
  color: #fff;
  line-height: 14px;
  padding: 0 4px;
}
.centerpage .user .user-contact {
  position: absolute;
  top: 58px;
  right: 0;
  height: 35px;
  line-height: 35px;
  padding: 0 18px 0 42px;
  font-size: 15px;
  color: #fff;
  background-image: url(//fe1.hdfimg.com/nusercenter/ssi/wapusercenter/prd/image/contact_service.png?2020021001);
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 370px) {
  .centerpage .user .user-contact {
    top: 50px;
    padding-right: 14px;
  }
}
.centerpage .user .user-contact .user-contact-red {
  position: absolute;
  top: 4px;
  right: 4px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: red;
}
.centerpage .order {
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: 8px;
  margin: 20px 10px 0;
  padding: 12px 14px;
}
.centerpage .order .order-hd {
  display: block;
  font-size: 17px;
  color: #000;
  padding-bottom: 12px;
  border-bottom: 1px solid #dcdcdc;
}
.centerpage .order .order-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  box-pack: space-between;
  padding-top: 12px;
}
.centerpage .order .order-block .order-block-item {
  position: relative;
}
.centerpage .order .order-block .order-block-item:last-child:before {
  position: absolute;
  display: inline-block;
  content: "";
  top: 0;
  left: -14px;
  width: 8px;
  height: 48px;
  background-image: url(//i2.hdfimg.com/ssi/usercenter/image/order_line.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.centerpage .order .order-block .order-block-item .o-b-i-icon {
  display: block;
  width: 36px;
  height: 28px;
  margin: auto;
}
.centerpage .order .order-block .order-block-item .o-b-i-content {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #333;
  margin-top: 5px;
}
.centerpage .order .order-block .order-block-item .o-b-i-count {
  position: absolute;
  top: -9px;
  left: 34px;
  border: 1px solid red;
  border-radius: 7px;
  font-size: 10px;
  color: red;
  padding: 0 4px;
}
.centerpage .account {
  background-color: #fff;
  border-radius: 8px;
  margin: 10px 10px 0;
  padding: 12px 14px 10px;
}
.centerpage .account .account-hd {
  font-size: 17px;
  color: #000;
  padding-bottom: 12px;
  border-bottom: 1px solid #dcdcdc;
}
.centerpage .account .account-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -moz-box-pack: space-around;
  -webkit--moz-box-pack: space-around;
  box-pack: space-around;
  padding-top: 10px;
}
.centerpage .account .account-block .a-b-i-value {
  display: block;
  text-align: center;
  font-size: 17px;
  color: #333;
}
.centerpage .account .account-block .a-b-i-value .value-unit {
  font-size: 12px;
  color: #646464;
}
.centerpage .account .account-block .a-b-i-content {
  display: block;
  text-align: center;
  font-size: 13px;
  color: #999;
}
.centerpage .medical-record {
  height: 50px;
  background: #fff;
  display: flex;
  border-radius: 5px;
  align-items: center;
  box-sizing: border-box;
  margin: 10px 10px 0;
  padding: 12px 14px 10px;
}
.centerpage .medical-record .record-text {
  font-size: 17px;
  color: #000;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.centerpage .medical-record .record-text:before {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  content: "";
  background: url(https://fe1.hdfimg.com/nusercenter/ssi/wapusercenter/prd/image/userindex/bingan.png)
    no-repeat center center/100% 100%;
}
.centerpage .medical-record .record-link {
  position: absolute;
  right: 30px;
  width: 8px;
  height: 8px;
  border: 1px solid #969696;
  border-top: 0;
  border-left: 0;
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.centerpage .medical-record .record-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.centerpage .tools {
  background-color: #fff;
  border-radius: 8px;
  margin: 10px 10px 0;
  padding: 12px 14px 20px;
}
.centerpage .tools .tools-hd {
  font-size: 17px;
  color: #000;
  padding-bottom: 12px;
  border-bottom: 1px solid #dcdcdc;
}
.centerpage .tools .tools-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
}
.centerpage .tools .tools-block .tools-block-item {
  position: relative;
  -webkit-box-flex: 0 1 25%;
  -webkit-flex: 0 1 25%;
  -moz-box-flex: 0 1 25%;
  -ms-flex: 0 1 25%;
  flex: 0 1 25%;
  margin-top: 20px;
}
.centerpage .tools .tools-block .tools-block-item .t-b-i-icon {
  display: block;
  width: 30px;
  height: 30px;
  margin: auto;
}
.centerpage .tools .tools-block .tools-block-item .t-b-i-content {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #333;
  margin-top: 9px;
}
.centerpage .tools .tools-block .tools-block-item .t-l-i-redpoint {
  position: absolute;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: red;
  top: 0;
  right: 19%;
}
.centerpage .menu {
  position: fixed;
  right: 10px;
  bottom: 135px;
  z-index: 999;
  text-align: right;
}
.centerpage .menu .menu-hdflogo {
  display: inline-block;
  width: 80px;
  height: 80px;
  background-image: url(//fe1.hdfimg.com/nusercenter/ssi/wapusercenter/prd/image/userindex/icon_wenzhen.png?2020021001);
  background-repeat: no-repeat;
  background-size: cover;
}
.centerpage .menu .menu-hdflogo.clicked {
  opacity: 1;
}
.centerpage .menu .menu-list {
  display: none;
  position: relative;
  background-color: #fff;
  border: 1px solid #46a0f0;
  border-radius: 4px;
  margin-bottom: 15px;
}
.centerpage .menu .menu-list .menu-list-item:last-child .m-l-i-link {
  border-bottom: 0;
}
.centerpage .menu .menu-list .menu-list-item .m-l-i-link {
  color: #46a0f0;
  width: 90px;
  display: block;
  border-bottom: 1px solid #46a0f0;
  margin: 0 10px;
  text-align: center;
  padding: 11px 0;
  font-size: 15px;
}
.centerpage .menu .menu-list .menu-list-item.item-download {
  display: none;
}
#js-mask {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: #000;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
#js-bubble {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  color: #fff;
  background-color: #000;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 25px 20px;
  width: 240px;
  max-height: 450px;
  overflow: scroll;
  font-size: 17px;
  text-align: center;
  z-index: 99999;
  margin-left: -140px;
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 24px;
  letter-spacing: 1px;
  word-wrap: break-word;
}
.box-notice {
  position: fixed;
  bottom: 66px;
  bottom: calc(constant(safe-area-inset-bottom) + 69.5px);
  bottom: calc(env(safe-area-inset-bottom) + 69.5px);
  left: 0;
  width: 100%;
  height: 43px;
  padding: 0 12px;
  box-sizing: border-box;
  z-index: 1;
}
.box-notice.no-navbottom {
  bottom: calc(constant(safe-area-inset-bottom) + 21.5px);
  bottom: calc(env(safe-area-inset-bottom) + 21.5px);
}
.box-notice .nbar {
  opacity: 0.85;
  background: #28344f;
  border-radius: 21.5px;
  padding: 11px 12px;
  position: relative;
}
.box-notice .nbar .n-text {
  font-size: 15px;
  color: #fff;
  letter-spacing: 0;
  line-height: 21px;
  height: 21px;
  float: left;
  padding-left: 25px;
  background-image: url("//fe1.hdfimg.com/nindex/ssi/index/prd/image/notice.png");
  background-repeat: no-repeat;
  background-position: left 3px center;
  background-size: auto 15px;
  white-space: nowrap;
  width: calc(100% - 90px);
  text-overflow: ellipsis;
  overflow: hidden;
}
.box-notice .nbar .n-text .nt-title {
  width: 60px;
  float: left;
}
.box-notice .nbar .n-text .nt-content {
  float: left;
  width: calc(100% - 70px);
  position: relative;
  overflow: hidden;
  height: 100%;
}
.box-notice .nbar .n-text .nt-content .ntc-item {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
}
.box-notice .nbar .n-text .nt-content .ntc-item p {
  display: inline-block;
}
.box-notice .nbar .n-text .nt-content .ntc-item p.pr {
  padding-right: 20px;
}
@media screen and (max-width: 360px) {
  .box-notice .nbar .n-text .nt-title {
    display: none;
  }
  .box-notice .nbar .n-text .nt-content {
    float: left;
    width: calc(100% - 10px);
  }
}
.box-notice .nbar .n-text .count {
  color: #ffc653;
}
.box-notice .nbar .n-btn {
  font-size: 15px;
  color: #c0e1ff;
  letter-spacing: 0;
  text-align: right;
  float: right;
  position: relative;
  padding-right: 15px;
  line-height: 21px;
}
.box-notice .nbar .n-btn::after {
  content: " ";
  display: block;
  position: absolute;
  right: 12.5px;
  top: 5.5px;
  height: 7px;
  width: 7px;
  border-width: 1px 1px 0 0;
  border-color: #c0e1ff;
  border-style: solid;
  transform: matrix(0.71, -0.71, -0.71, -0.71, 6, 1);
}
.box-notice .nbar .n-point {
  position: absolute;
  top: -5px;
  right: 15px;
  min-width: 15px;
  height: 15px;
  background: red;
  box-sizing: border-box;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  padding: 0 3px;
  border-radius: 7.5px;
  z-index: 10;
}
</style>